<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Admin Reports</h3>
      </div>
    </div>

    <div class="card-body border-top p-9">
        <div class="card-p position-relative">
            <div class="row m-0">
                <a href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_transcript_option"
                  id="transcript_option_button"
                  class="col bg-light-warning px-6 py-8 rounded-2 me-7" 
                  style="text-align: center;cursor:pointer;">
                    <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                    </span>
                    <span class="text-primary fw-bold fs-6">Transcript </span>
                </a>
                <a @click="printEnrollment" class="col bg-light-danger px-6 py-8 rounded-2 me-7" style="text-align: center;cursor:pointer;">
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                    </span>
                    <span class="text-danger fw-bold fs-6 mt-2"> Enrollment Verification</span>
                </a>
                <a href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_attendance_option"
                  id="attendance_option_button" 
                  class="col bg-light-success px-6 py-8 rounded-2" 
                  style="text-align: center;cursor:pointer;">
                    <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                    <inline-svg src="/media/icons/duotune/files/fil008.svg" />
                    </span>
                    <span class="text-success fw-bold fs-6 mt-2"> Attendance Report </span>
                </a>
            </div>
        </div>
    </div>
  </div>
  <TranscriptOption />  
  <AttendanceOption />
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import Mustache from "mustache";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
import TranscriptOption from "@/views/student/modals/TranscriptOption.vue"
import AttendanceOption from "@/views/student/modals/AttendanceOption.vue"

export default defineComponent({
  name: "student-reports",
  components: {
    TranscriptOption,
    AttendanceOption
  },
  setup() {
    const route = useRoute();
    const studentId = route.params.id;
    const signatureId = 43;
    const profileDetails = computed(() => {
        return store.getters.currentStudentProfile.studentProfile;
    });
    const signatureUrl = ref('');
    const enrollmentId = computed(() => {
        return profileDetails.value.status === "Active" ? 3 : 2;
    });
    
    const today = new Date();

    const printAttendanceReport = async() =>{
        Swal.fire({
                    text: "Waiting for the requirement",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
    }

    const printEnrollment = async() =>{
        ApiService.setHeader();
        await ApiService.get("report/"+enrollmentId.value)
                .then(({ data }) => {
                    getSignatureUrl();
                    getProgram().then((programData : any) =>{
                      let programNames = Object.values(_.groupBy(programData, data => data.subject.session.level.program.name))
                                    .reverse()
                                    .map(x => x[0].subject.session.level.program.name +"("+x[0].subject.session.level.program.code+")")
                                    .join(',');
                      let enrollmentData : any = {};
                      enrollmentData.print_date = moment(today).format('MMMM DD, YYYY');
                      enrollmentData.name = profileDetails.value.first_name + ' ' + profileDetails.value.last_name;
                      enrollmentData.birth_date = moment(profileDetails.value.birth_date).format('MM/DD/YYYY');
                      enrollmentData.start_date = moment(profileDetails.value.start_date).format('MM/DD/YYYY');
                      enrollmentData.end_date = moment(profileDetails.value.end_date).format('MM/DD/YYYY');
                      enrollmentData.program_name = programNames;
                      enrollmentData.signature_url = signatureUrl.value;
                      let template = data.data.report_content;
                      let printedEnrollment = Mustache.render(template, enrollmentData);
                      let reportWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
                      if(reportWindow){
                          reportWindow.document.body.innerHTML = printedEnrollment;
                      };
                    });
                })
                .catch(({ response }) => {
                  console.log('printEnrollment error => '+response.data.errors);
                });
    }

    const getSignatureUrl = async() =>{
        ApiService.setHeader();
        await ApiService.get("file/url/"+signatureId)
                .then(({ data }) => {
                  signatureUrl.value = data.url;
                });
    }

    const getProgram = () =>{
      return new Promise((resolve, reject) => {
        ApiService.setHeader();          
        ApiService.get("class-student/subjects/summary/"+studentId)
            .then(({ data }) => {
                if(data.data){
                  resolve(data.data);
                }
                else{
                  Swal.fire({
                    text: "This student doesn't have any registered program. Please try to reprint after register one or more programs for this student.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                  reject(new Error ('getProgramNames error => there is no program data'));
                }
            })
            .catch(({ response }) => {
                console.log('getProgramNames error => '+response.data.errors);
                reject(new Error ('getProgramNames error => '+response.data.errors));
            });
      });
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Reports", ["Student"]);
    });

    return {
      studentId,
      printEnrollment,
      printAttendanceReport
    };
  },
});
</script>

<style scoped>
</style>

