<template>
  <div
    class="modal fade"
    id="modal_attendance_option"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                Attendance Report option
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-12"
              >
                <span class="required">Previous Sessions</span>
              </label>
              <div class="col-lg-12">
                <el-form-item prop="type">
                  <el-select
                      class="form-select-solid w-100"
                      placeholder="Select session"
                      v-model="selectedSessionId"
                  >
                    <el-option 
                      v-for="session in sessions" 
                      :label="session.name" 
                      :value="session.id"
                      :key="session.id">
                      {{ session.name}}
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="text-center">
              <el-button type="primary" @click="print()" :loading="loading">
                {{ loading ? "Please wait..." : "Print" }}
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";
import Mustache from "mustache";

export default defineComponent({
  name: "transcript-option-modal",
  components: {
  },
  setup() {
      const store = useStore();
      const route = useRoute();
      const formRef = ref<null | HTMLFormElement>(null);
      const loading = ref<boolean>(false);
      const attendanceReportId = 7;
      const recordCount = 47;
      const studentId = route.params.id;
      const selectedSessionId = ref<number>();
      const profileDetails = computed(() => {
          return store.getters.currentStudentProfile.studentProfile;
      });
      const sessions = ref<any>([]);

      const getSessions = () =>{
          getSubjects().then((subjectData : any) =>{
              sessions.value = Object.values(_.groupBy(subjectData, data => data.subject.session_id))
                                      .reverse()
                                      .sort(function(a,b) { 
                                          if(a[0].id == b[0].id) return 0;

                                          if (Date.parse(a[0].subject.session.level.program.semester.end_date!) < Date.parse(b[0].ubject.session.level.program.semester.end_date!))
                                              return 1;
                                          if (Date.parse(a[0].subject.session.level.program.semester.end_date!) > Date.parse(b[0].ubject.session.level.program.semester.end_date!))
                                              return -1;
                                          return 0;
                                      })
                                      .map(x => { let subject = x[0].subject; return {
                                          id : subject.session_id, 
                                          name : subject.session.level.program.semester.name +" "+subject.session.level.program.name+" " +subject.session.level.name+ " "+ subject.session.name + "("+ moment(subject.session.level.program.semester.start_date).format('MMMM DD, YYYY') + " ~ " + moment(subject.session.level.program.semester.end_date).format('MMMM DD, YYYY')+")"}});
          });
      }

      const getSubjects = () =>{
          return new Promise((resolve, reject) => {
          ApiService.setHeader();          
          ApiService.get("class-student/subjects/summary/"+studentId)
              .then(({ data }) => {
                  if(data.data){
                    resolve(data.data);
                  }
                  else{
                    Swal.fire({
                      text: "This student doesn't have any registered program. Please try to reprint after register one or more programs for this student.",
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "Ok, got it!",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    });
                    reject(new Error ('getProgramNames error => there is no program data'));
                  }
              })
              .catch(({ response }) => {
                  console.log('getProgramNames error => '+response.data.errors);
                  reject(new Error ('getProgramNames error => '+response.data.errors));
              });
        });
      }

      const getSessionAttendanceReportData = () =>{
        let today = new Date();
        return new Promise((resolve, reject) => {
          ApiService.setHeader();          
          ApiService.get("class-student/attendance/"+studentId+"/session/"+selectedSessionId.value+"/date/"+today)
              .then(({ data }) => {
                  if(data.data){
                    resolve(data.data);
                  }
                  else{
                    reject(new Error ('getSessionAttendanceReportData error => there is no session trasncript data'));
                  }
              })
              .catch(({ response }) => {
                  console.log('getSessionAttendanceReportData error => '+response.data.errors);
                  reject(new Error ('getSessionAttendanceReportData error => '+response.data.errors));
              });
        });
      }

      const print = async() =>{
          ApiService.setHeader();
          await ApiService.get("report/"+attendanceReportId)
                  .then(({ data }) => {
                      getSessionAttendanceReportData().then(async(sessionData: any) =>{
                          let attendanceList = sessionData.attendance_list
                          .map(x=> { return {
                            attendance_date : moment(x.attendance_date).utc().format('MM/DD/YYYY'),
                            class_name : x.subject.code,
                            attendance_status : x.attendance_status
                          }})
                          .sort(function(a,b) { 
                              if (Date.parse(a.attendance_date!) < Date.parse(b.attendance_date!))
                                  return 1;
                              if (Date.parse(a.attendance_date!) > Date.parse(b.attendance_date!))
                                  return -1;
                              return 0;
                          });
                          
                        let reportData : any = {};
                        reportData.name = profileDetails.value.first_name + ' ' + profileDetails.value.last_name;
                        reportData.birth_date = moment(profileDetails.value.birth_date).format('MM/DD/YYYY');
                        reportData.student_id = profileDetails.value.student_id;
                        reportData.start_date = moment(sessionData.start_date).format('MM/DD/YYYY');
                        reportData.end_date = moment(sessionData.end_date).format('MM/DD/YYYY');
                        reportData.per_week_hours = sessionData.per_week_hours;
                        reportData.total_hours = sessionData.total_hours;
                        reportData.attendance_hours = sessionData.attendance_hours;
                        reportData.attendance_rate = sessionData.attendance_rate;
                        reportData.attendance1 = attendanceList.slice(0,recordCount);
                        
                        if(attendanceList.length > recordCount){
                          reportData.attendance2 = attendanceList.slice(recordCount);
                        }

                        let template = data.data.report_content;
                        let printedTranscript = Mustache.render(template, reportData);

                        let reportWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
                        if(reportWindow){
                            reportWindow.document.body.innerHTML = printedTranscript;
                        };
                      });
                  })
                  .catch(({ response }) => {
                    console.log('print error => '+response.data.errors);
                  });
      }

    onMounted(() =>{
        getSessions();
    });

    return {
      loading,
      formRef,
      moment,
      print,
      selectedSessionId,
      sessions
    };
  },
});
</script>

