<template>
  <div
    class="modal fade"
    id="modal_transcript_option"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                Transcript option
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="resetSelectedData"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            id="transcript_option_form"
            :model="optionData"
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-12"
              >
                <span class="required">Previous Sessions</span>
              </label>
              <div class="col-lg-12">
                <el-form-item prop="type">
                    <el-checkbox-group
                      v-model="selectedSessions"
                      size="medium"
                    >
                        <el-checkbox-button
                          v-for="session in sessions"
                          :key="session.id"
                          :label="session.id"
                        >
                          {{ session.name }} 
                        </el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
            <div class="text-center">
              <el-button type="primary" @click="print()" :loading="loading">
                {{ loading ? "Please wait..." : "Print" }}
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";
import Mustache from "mustache";

export default defineComponent({
  name: "transcript-option-modal",
  components: {
  },
  setup() {
      const store = useStore();
      const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const transcriptId = 5;
    const transcriptAddtionalId = 6;
    const signatureId = 101;
    const signatureUrl = ref('');
    const studentId = route.params.id;
    const selectedSessions = ref<any>([]);
    const profileDetails = computed(() => {
        return store.getters.currentStudentProfile.studentProfile;
    });
    const sessions = ref<any>([]);
    const today = new Date();

    const getSignatureUrl = async() =>{
        ApiService.setHeader();
        await ApiService.get("file/url/"+signatureId)
                .then(({ data }) => {
                  signatureUrl.value = data.url;
                });
    }

    const getSessions = () =>{
        getSubjects().then((subjectData : any) =>{
            var today = new Date();
            subjectData = subjectData.filter(x=>moment(x.subject.session.level.program.semester.end_date) <= moment(today));
            sessions.value = Object.values(_.groupBy(subjectData, data => data.subject.session_id))
                                    .reverse()
                                    .sort(function(a,b) { 
                                        if(a[0].id == b[0].id) return 0;

                                        if (Date.parse(a[0].subject.session.level.program.semester.end_date!) < Date.parse(b[0].subject.session.level.program.semester.end_date!))
                                            return 1;
                                        if (Date.parse(a[0].subject.session.level.program.semester.end_date!) > Date.parse(b[0].subject.session.level.program.semester.end_date!))
                                            return -1;
                                        return 0;
                                    })
                                    .map(x => { let subject = x[0].subject; return {
                                        id : subject.session_id, 
                                        name : subject.session.level.program.semester.name +" "+subject.session.level.program.name+" " +subject.session.level.name+ " "+ subject.session.name + "("+ moment(subject.session.level.program.semester.start_date).format('MMMM DD, YYYY') + " ~ " + moment(subject.session.level.program.semester.end_date).format('MMMM DD, YYYY')+")"}});                       
        });
    }

    const getSubjects = () =>{
        return new Promise((resolve, reject) => {
        ApiService.setHeader();          
        ApiService.get("class-student/subjects/summary/"+studentId)
            .then(({ data }) => {
                if(data.data){
                  resolve(data.data);
                }
                else{
                  Swal.fire({
                    text: "This student doesn't have any registered program. Please try to reprint after register one or more programs for this student.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                  reject(new Error ('getProgramNames error => there is no program data'));
                }
            })
            .catch(({ response }) => {
                console.log('getProgramNames error => '+response.data.errors);
                reject(new Error ('getProgramNames error => '+response.data.errors));
            });
      });
    }

    const getSessionTranscriptData = () =>{
      return new Promise((resolve, reject) => {
        ApiService.setHeader();          
        ApiService.get("class-student/transcript?studentId="+studentId+"&ids="+selectedSessions.value.join(","))
            .then(({ data }) => {
                if(data.data){
                  resolve(data.data);
                }
                else{
                  reject(new Error ('getSessionTranscriptData error => there is no session trasncript data'));
                }
            })
            .catch(({ response }) => {
                console.log('getSessionTranscriptData error => '+response.data.errors);
                reject(new Error ('getSessionTranscriptData error => '+response.data.errors));
            });
      });
    }

    const print = async() =>{
        ApiService.setHeader();
        await ApiService.get("report/"+transcriptId)
                .then(({ data }) => {
                    getSignatureUrl();
                    getSessionTranscriptData().then(async(transcriptSessionData: any) =>{
                      let sessionData = [...transcriptSessionData.sessions];
                      let transcriptData : any = {};
                      transcriptData.print_date = moment(today).format('MMMM DD, YYYY');
                      transcriptData.name = profileDetails.value.first_name + ' ' + profileDetails.value.last_name;
                      transcriptData.birth_date = moment(profileDetails.value.birth_date).format('MM/DD/YYYY');
                      transcriptData.student_id = profileDetails.value.student_id;
                      transcriptData.sevis_no = profileDetails.value.sevis_no;
                      transcriptData.admission_date = moment(profileDetails.value.start_date).format('MM/DD/YYYY');
                      transcriptData.sessions1 = transcriptFormat(sessionData.slice(0,4));
                      
                      if(sessionData.length > 4){
                        transcriptData.sessions2 = transcriptFormat(sessionData.slice(4,8));
                        if(sessionData.length <=8){
                          transcriptData.sessions2_end_of_record = getEndOfRecord(transcriptSessionData.last_date_of_attendance);
                        }
                      }
                      else{
                        transcriptData.sessions1_end_of_record = getEndOfRecord(transcriptSessionData.last_date_of_attendance);
                      }

                      if(sessionData.length <=8){
                        transcriptData.signature = {};
                        transcriptData.signature.signature_url = signatureUrl.value; 
                      }

                      let totalPage = 1+ (sessionData.length <=8 ? 0 : Math.floor((sessionData.length-8)/10)+1);
                      transcriptData.number_of_total_page = totalPage;
                      let template = data.data.report_content;
                      let printedTranscript = Mustache.render(template, transcriptData);
                      
                      if(sessionData.length >8){
                        let pageNo = 2;
                        for(let i= 8; i<sessionData.length;i=i+10){
                          let lastIndex = sessionData.length < i+10 ? sessionData.length : i+10;
                          await getAdditionalPage(sessionData.slice(i,lastIndex), pageNo, totalPage, lastIndex == sessionData.length, transcriptSessionData.last_day_of_attendance).then((data)=>{
                            printedTranscript += data;
                          });
                          pageNo++;
                        }
                      }

                      let reportWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
                      if(reportWindow){
                          reportWindow.document.body.innerHTML = printedTranscript;
                      };
                    });
                })
                .catch(({ response }) => {
                  console.log('printTranscript error => '+response.data.errors);
                });
    }

    const getAdditionalPage = (sessionData, noOfPage, totalPage, showEndOfRecord, last_date_of_attendance) =>{
      return new Promise((resolve, reject) => {
        ApiService.setHeader();   
        ApiService.get("report/"+transcriptAddtionalId)
          .then(({ data }) => {
            let transcriptData : any = {};
            transcriptData.current_page = noOfPage;
            transcriptData.number_of_total_page = totalPage;
            transcriptData.print_date = moment(today).format('MMMM DD, YYYY');
            transcriptData.sessions1 = transcriptFormat(sessionData.slice(0,5));
            if(sessionData.length > 5){
              transcriptData.sessions2 = transcriptFormat(sessionData.slice(5,10));
              if(sessionData.length <=10 && showEndOfRecord){
                transcriptData.sessions2_end_of_record = getEndOfRecord(last_date_of_attendance);
              }
            }
            else{
              transcriptData.sessions1_end_of_record = getEndOfRecord(last_date_of_attendance);
            }

            if(showEndOfRecord){
              transcriptData.signature = {};
              transcriptData.signature.signature_url = signatureUrl.value;  
            }
            let template = data.data.report_content;
            let printedTranscript = Mustache.render(template, transcriptData);
            resolve(printedTranscript);
          });
      });
    }

    const getEndOfRecord = (lastDateOfAttendance : any) =>{
        return { 
            last_day_of_attendance : profileDetails.value.end_date ? moment(profileDetails.value.end_date).format('MM/DD/YYYY') : lastDateOfAttendance
        }; 
    }

    const transcriptFormat = (sessionData) =>{
      let sessions = [] as any;
      sessionData.forEach(session => {
        sessions.push({
          semester_name : session.session.level.program.semester.name,
          semester_range : session.semester_range,
          level_code: session.session.level.code,
          level_name : session.session.level.name,
          session_hours: session.session.hours_per_week,
          attendance_rate: session.attendance_rate,
          total_semester_score: session.total_semester_score,
          pass_fail: session.pass_fail,
          grades:session.scores
        });
      })

      return sessions;
    }

    const resetSelectedData = () =>{
      selectedSessions.value = [];
    }

    onMounted(() =>{
        getSessions();
    });

    return {
      loading,
      formRef,
      moment,
      print,
      selectedSessions,
      sessions,
      resetSelectedData
    };
  },
});
</script>

<style scoped>
* >>> .el-checkbox-button__inner {
  border-radius: unset !important;
  border-left: 1px solid #dcdfe6 !important;
}
</style>